.section-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 100%;
    max-width: 500px;
  }

  &__top-label {
    margin-top: 25px;
    p.MuiTypography-root {
      text-align: center;
    }
  }
}

.listing-card {
  max-width: 500px;
  margin: 20px;
  width: 100%;
  position: relative;

  .MuiCardActions-root {
    padding: 12px;
    padding-bottom: 14px;
  }

  &__flag {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &__smart-index.MuiTypography-root {
    margin-top: 10px;
  }
}

.all-bars {
  margin-top: 20px;
}

.blue {
  .MuiLinearProgress-barColorPrimary {
    background-color: #3f51b5;
  }
}

.yellow {
  .MuiLinearProgress-barColorPrimary {
    background-color: #c0ca33;
  }
}

.red {
  .MuiLinearProgress-barColorPrimary {
    background-color: #dd2c00;
  }
}

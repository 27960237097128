.location-loader {
  margin-top: 20vh;

  .Main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MapIcon {
    overflow: visible;
    transform: scale(3);
  }

  .IconPing {
    animation: IconPing 1.5s linear infinite;
    fill: #ea4335;
    transform-origin: center center;
    vector-effect: non-scaling-stroke;
  }

  @keyframes IconPing {
    0% {
      transform: scale(0.9);
      opacity: 0.6;
    }

    40% {
      opacity: 0.4;
    }

    60% {
      transform: scale(1.5) translate(0, 4px);
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}

// Colors
:root {
  --color-green: green;
  --color-grey: grey;
  --color-blue: #3f51b5;
}
.main-wrapper {
  .button-positive {
    background-color: var(--color-green);
  }
}

html {
  background-color: #e2e2e2;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-weight: 100;
  font-size: 18px;

  strong {
    font-weight: 900;
  }
}

.main-wrapper__content {
  background-image: url("static/globe.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  min-height: 70vh;

  // .fb-comments {
  //   width: 100%;
  //   background: white;
  //   padding: 25px;
  //   margin-bottom: 50px;
  // }

  #disqus_thread {
    width: 100%;
    background: white;
    padding: 20px;
    box-sizing: border-box;
  }
}

.footer {
  text-align: center;
}

.main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .margin-left-auto {
    margin-left: auto;
  }

  &__content {
    margin: 0 15px;
  }

  &__content .content {
    max-width: 768px;
    margin: auto;

    &__antet {
      margin-bottom: 50px;
      * {
        text-align: center;
        font-weight: 100;
      }
      strong {
        font-weight: 400;
      }
    }
  }
}

.center-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  img {
    width: 100%;
  }

  a {
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    max-width: 465px;
    display: block;
    width: 60vw;
  }
}

h1 {
  text-align: center;
  color: var(--color-blue);
  clear: both;
}

h2 {
  color: var(--color-blue);
  clear: both;
}

h3 {
  color: var(--color-blue);
  clear: both;
}

.content {
  p {
    text-align: justify;
  }
}

.clear {
  clear: both;
}

.content-image {
  max-width: 100%;
  margin-bottom: 15px;

  &.half-left {
    float: left;
    margin-right: 15px;
  }

  &.half-right {
    float: right;
    margin-left: 15px;
  }

  &.half-left,
  &.half-right {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
      float: none;
      margin: auto;
    }
  }
}

.center-text {
  text-align: center;
}

.fb-comments.fb_iframe_widget ~ .small-loader {
  display: none;
}
.fb-comments {
  box-sizing: border-box;
}
.app-fb-comments {
  width: 100%;
  background-color: white;
  & > div,
  & > div > div {
    width: 100%;
    box-sizing: border-box;
  }
}
